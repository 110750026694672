import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom';

import './assets/main.css'
import App from './App'

import { ThemeProvider } from './context/ThemeContext'
import { VaultProvider } from './context/VaultContext'
import { ChainProvider } from './context/ChainContext'

import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { localhost, mainnet, polygon } from 'wagmi/chains'

import { InjectedConnector } from 'wagmi/connectors/injected'

const { chains, publicClient } = configureChains(
  [localhost, mainnet, polygon],
  [publicProvider()],
)

const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
})

const root = createRoot(document.getElementById('root'))
root.render(
  <StrictMode>
    <ThemeProvider>
      <WagmiConfig config={config}>
        <ChainProvider>
          <VaultProvider>
            <Router>
              <App />
            </Router>
          </VaultProvider>
        </ChainProvider>
      </WagmiConfig>
    </ThemeProvider>
  </StrictMode>
)
