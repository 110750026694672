import { ethers } from 'ethers'
import React, { useState, useEffect } from 'react'

import { useAccount, useConnect, useEnsName } from 'wagmi'
import { truncateEthAddress } from '../lib/utils'

import toast from '../components/toast'
import ChainsDropDown from './ChainsDropDown'
import { getNetwork } from '../lib/contracts'

export default function Header () {
  const [chainId, setChainId] = useState();

  const { connect, connectors } = useConnect({
    onError(e) {
      toast.error({
        code: e.code,
        title: e.name,
        message: e.details
      })
    },
  })
  const connector = connectors[0]
  const { address, isConnected } = useAccount()
  const { data: ensName } = useEnsName({ address })


  useEffect(() => {
    async function checkSigner() {
      try {
        const {ethereum} = window
        const provider = new ethers.BrowserProvider(ethereum)
        const signer = await provider.getSigner()
        const chainId = signer.provider._network.chainId;
  
        setChainId(chainId)
      } catch (e) {
        // console.log(e)
      }
    }

    checkSigner()
  }, [])

  async function handleWalletChanges() {
    const {ethereum} = window
    const provider = new ethers.BrowserProvider(ethereum)
    const signer = await provider.getSigner()
    const chainId = signer.provider._network.chainId;

    setChainId(chainId)
  }
  
  useEffect(() => {
    try {
      window.ethereum.on('accountsChanged', handleWalletChanges);
      window.ethereum.on('chainChanged', handleWalletChanges);
    } catch (e) {
      // console.log(e)
    }

    return () => {
      try {
        window.ethereum.removeListener('accountsChanged', handleWalletChanges);
        window.ethereum.removeListener('chainChanged', handleWalletChanges);
      } catch (e) {
      }
    };
  }, []);

  async function handleChange(selected) {
    const network = await getNetwork()

    if (network) {
      if (network.chainId === Number(selected)) {
        return;
      }
    }

    try {
      const hexId = ethers.toQuantity(Number(selected))
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexId }],
      })
    } catch (switchError) {
      if (switchError.code === 4902) {
        const network = networks[selected]
        const hexId = ethers.toQuantity(Number(selected))

        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: hexId,
              chainName: network.name,
              rpcUrls: [network.rpcurl],
              nativeCurrency: {
                name: network.native,
                symbol: network.native,
                decimals: 18
              },
              blockExplorerUrls: [network.explorer]
            }]
          })
        } catch (addError) {
          // console.log(addError)
          // toast.error({
          //   code: addError.code,
          //   title: addError.name,
          //   message: e.details
          // })
        }

      }
    }

  }

  function renderButton() {
    return (
      isConnected &&
      <a className="cursor-pointer inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75">
        <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
          {ensName ?? truncateEthAddress(address)}
        </span>
      </a> ||
      <a onClick={() => connect({ connector })} className="cursor-pointer inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] text-gray-800 hover:text-white focus:outline-none focus:ring active:text-opacity-75">
        <span className="block rounded-sm bg-white px-8 py-3 text-sm font-medium hover:bg-transparent">
          Connect Wallet
        </span>
      </a>
    )
  }

  return (
    <div className="fixed w-full z-30 flex bg-white dark:bg-[#0F172A] p-2 items-center justify-center h-16 pl-10">
      <div className="hidden logo ml-12 dark:text-white transform ease-in-out duration-500 flex-none h-full flex items-center justify-center">
        XenVault
      </div>
      <div className="grow h-full flex items-center justify-center"></div>

      <div className="flex-none h-full text-center flex items-center justify-center">
        <div className="flex space-x-3 items-center md:px-3">
          <div className="hidden">Balance: 0.000 ETH</div>
          <div className=""><ChainsDropDown handleChange={handleChange} defaultSelected={chainId} /></div>
          <div className="flex-none flex justify-center">
          {renderButton()}
          </div>
        </div>
      </div>
    </div>
  )
}
