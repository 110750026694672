import React, { useState, useEffect, useRef } from 'react';
import networks from '../assets/networks'


export default function ChainsDropDown({ handleChange, defaultSelected }) {
  const [inputValue, setInputValue] = useState('Select Network');
  const [visible, setDropdownVisibility] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisibility(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (defaultSelected) {
      let label;

      // dont error out use the not enabled param
      try {
        if (!networks[defaultSelected].enabled) {
          throw Error()
        }
        label = networks[defaultSelected].name

      } catch (e) {
        // console.log(e)
        label = 'Chain Not Enabled'
      }
      
      setInputValue(label)
    }
  }, [defaultSelected]);

  const handleNetworkChange = (event) => {
    const label = networks[event].name

    setInputValue(label);
    handleChange(event)
    setDropdownVisibility(!visible);
  };

  const toggleDropdown = () => {
    setDropdownVisibility(!visible);
  };

  return (
    <div className="flex flex-col items-center justify-center" ref={dropdownRef}>
      <div className="relative inline-block text-left">
        <button onClick={toggleDropdown} className="dark:border-gray-800 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-200 inline-flex justify-center w-full px-5 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          {inputValue}
          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>

        <div className={`${visible ? '' : 'hidden' } md:w-56 transition-all duration-300 transform origin-top-right absolute end-0 z-10 mt-2 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg dark:divide-gray-800 dark:border-gray-800 dark:bg-gray-900`} role="menu">
          <div className="p-2">
            {Object.keys(networks).map(chainId => {
              const chain = networks[chainId];

              if (chain.enabled) {
                return (
                  <a 
                    key={networks[chainId].name}
                    href="#" 
                    onClick={() => handleNetworkChange(chainId)} 
                    className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                  >
                    {networks[chainId].icon && <svg className="h-5 inline pr-2">{networks[chainId].icon}</svg>}
                    {networks[chainId].name}
                  </a>
                )
              }
            })}

          </div>
        </div>

      </div>
    </div>
  )

}
