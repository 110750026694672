import React, { useState } from 'react';

export default function StakingTabs({ onTabChange }) {
  const [activeTab, setActiveTab] = useState('stake');

  const handleTabClick = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      onTabChange(tab);
    }
  };

  return (
    <div className="flex justify-between items-center mt-4">
      <div className="flex bg-gray-200/[.5] hover:bg-gray-200 rounded-lg transition p-1 dark:bg-gray-700 dark:hover:bg-gray-600">
        <nav className="flex space-x-2" aria-label="Tabs" role="tablist">
          <button
            type="button"
            onClick={() => handleTabClick('stake')}
            className={`bg-${activeTab === 'stake' ? 'white' : 'gray-200/[.5]'} text-gray-700 shadow-sm text-xs text-gray-500 hover:text-gray-700 font-medium rounded-md py-2 px-3 dark:text-gray-900 dark:hover:text-gray-400 dark:bg-${activeTab === 'stake' ? 'gray-800' : 'gray-700'} dark:text-gray-900`}
          >
            Stake
          </button>
          <button
            type="button"
            onClick={() => handleTabClick('unstake')}
            className={`bg-${activeTab === 'unstake' ? 'white' : 'gray-200/[.5]'} text-gray-700 shadow-sm text-xs text-gray-500 hover:text-gray-700 font-medium rounded-md py-2 px-3 dark:text-gray-400 dark:hover:text-white dark:bg-${activeTab === 'unstake' ? 'gray-800' : 'gray-700'} dark:text-gray-400`}
          >
            Unstake
          </button>
        </nav>
      </div>
    </div>
  );
}
