import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { useAccount } from 'wagmi'
import { initContracts, getNetwork } from '../lib/contracts'

export default function Stats() {
  const [totalStats, setTotalStats] = useState({
    totalVaults: 0,
    totalXenLocked: 0,
    ethPool: 0,
    xenPool: 0,
    totalStaked: 0,
  })

  const [cycleStats, setCycleStats] = useState({
    currentCycle: 0,
    cycleTotalVaults: 0,
    cycleTotalLocked: 0,
    cycleFee: 0,
    cycleXenFee: 0,
    vaultReward: 0,
  })

  const { isConnected } = useAccount()

  async function getXenStats() {     
    try {
      const {ethereum} = window
      const provider = new ethers.BrowserProvider(ethereum)

      const contracts = await initContracts()
      const totalVaults = await contracts.xenvault.totalVaults()
      const totalXenLocked = ethers.formatEther(await contracts.xenvault.totalXenLocked())
      const ethPool = ethers.formatEther(await provider.getBalance(contracts.xenvault.target))
      const xenPool = ethers.formatEther(await contracts.xen.balanceOf(contracts.xenvault.target))
      const totalStaked = ethers.formatEther(await contracts.xenvault.totalStaked())

      setTotalStats({
        totalVaults,
        totalXenLocked,
        ethPool,
        xenPool,
        totalStaked,
      })

      const currentCycle = await contracts.xenvault.getCurrentCycle()
      const cycleTotalVaults = await contracts.xenvault.cycleTotalVaults(currentCycle)
      const cycleTotalLocked = ethers.formatEther(await contracts.xenvault.cycleTotalXenLocked(currentCycle))
      const cycleFee = ethers.formatEther(await contracts.xenvault.cycleAccruedFees(currentCycle))
      const cycleXenFee = ethers.formatEther(await contracts.xenvault.cycleAccruedXenFees(currentCycle))
      const vaultReward = ethers.formatEther(await contracts.xenvault.activeCycleReward())

      setCycleStats({
        currentCycle,
        cycleTotalVaults,
        cycleTotalLocked,
        cycleFee,
        cycleXenFee,
        vaultReward
      })

    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    if (isConnected) {
      getXenStats()
    }
  }, [])

  const [network, setNetwork] = useState()
  const label = (network) ? network.native : '';
  const xenname = (network) ? network.xenname : '';
  useEffect(() => {
    async function retrieveNetwork() {
      setNetwork(await getNetwork())
    }

    retrieveNetwork()
  }, [])


  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    async function fetchCycleData() {
      try {
        const contracts = await initContracts()
        const currentCycle = Number(await contracts.xenvault.getCurrentCycle());
        const genesisTs = Number(await contracts.xenvault.genesisTs());
  
        const cycleDuration = 24 * 60 * 60 * 1000;
        const nextCycleTs = genesisTs + currentCycle * cycleDuration + cycleDuration;
  
        const getTimeRemaining = (e) => {
          const total = Date.parse(e) - Date.parse(new Date());
          const seconds = Math.floor((total / 1000) % 60);
          const minutes = Math.floor((total / 1000 / 60) % 60);
          const hours = Math.floor((total / 1000 / 60 / 60) % 24);
          return {
              total, hours, minutes, seconds
          };
        }
  
        const updateCountdown = () => {
          const currentTime = Math.floor(Date.now() / 1000);
          const timeLeft = nextCycleTs - currentTime;
  
          if (timeLeft > 0) {
            let hours = Math.floor((timeLeft / (60 * 60)) % 24);
            let minutes = Math.floor((timeLeft / 60) % 60);
            let seconds = Math.floor(timeLeft % 60);
  
            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;
  
            setCountdown(`${hours}:${minutes}:${seconds}`);
          } else {
            setCountdown('00:00:00');
          }
        };
  
        updateCountdown()
        const intervalId = setInterval(updateCountdown, 1000);
  
        return () => clearInterval(intervalId);
      } catch (e) {
        // console.log(e)
      }
    }

    fetchCycleData();
  }, []);


  async function handleWalletChanges() {
    getXenStats()
    setNetwork(await getNetwork())
  }

  useEffect(() => {
    try {
      window.ethereum.on('accountsChanged', handleWalletChanges);
      window.ethereum.on('chainChanged', handleWalletChanges);
    } catch (e) {
      // console.log(e)
    }

    return () => {
      try {
        window.ethereum.removeListener('accountsChanged', handleWalletChanges);
        window.ethereum.removeListener('chainChanged', handleWalletChanges);
      } catch (e) {
      }
    };
  }, []);


  return (
    <div className="grid gap-x-4 md:grid-cols-2 py-2">

      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Total Stats</h2>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between mb-2">
              <div className="font-bold leading-none py-2">
                Total Number of Vaults:
              </div>
              <div className="leading-none py-2">
                {Number(totalStats.totalVaults).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Total Amount of {xenname || 'XEN'} locked:
              </div>
              <div className="leading-none py-2">
                {Number(totalStats.totalXenLocked).toLocaleString('en-us') || 0}
              </div>
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
              {label || 'ETH'} in Pool:
              </div>
              <div className="leading-none py-2">
                {Number(totalStats.ethPool).toFixed(8) || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
          <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
              {xenname || 'XEN'} in Pool
              </div>
              <div className="leading-none py-2">
              {Number(totalStats.xenPool).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
          <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Staked Vault:
              </div>
              <div className="leading-none py-2">
              {Number(totalStats.totalStaked).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

      </div>

      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Cycle Stats</h2>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Current Cycle:
              </div>
              <div className="leading-none py-2">
                {Number(Number(cycleStats.currentCycle) + 1).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Time left in Cycle:
              </div>
              <div className="leading-none py-2">
                {countdown}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Vault Rewards:
              </div>
              <div className="leading-none py-2">
                {Number(cycleStats.vaultReward).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between mb-2">
              <div className="font-bold leading-none py-2">
                Number of Vaults:
              </div>
              <div className="leading-none py-2">
                {Number(cycleStats.cycleTotalVaults).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Amount locked:
              </div>
              <div className="leading-none py-2">
              {Number(cycleStats.cycleTotalLocked).toLocaleString('en-us') || 0}
              </div>
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
            <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
                Cycle {label || 'ETH'} Rewards:
              </div>
              <div className="leading-none py-2">
                {Number(cycleStats.cycleFee).toFixed(8) || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800 mb-6">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] px-8 py-2 text-sm font-medium dark:text-white">
          <div className="flex justify-between">
              <div className="font-bold leading-none py-2">
              {xenname || 'XEN'} Rewards:
              </div>
              <div className="leading-none py-2">
                {Number(cycleStats.cycleXenFee).toLocaleString('en-us') || 0}
              </div>
            </div>

            <div className="my-2">
            </div>
          </span>
        </div>

      </div>

    </div>
  )
}
