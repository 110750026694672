import toast from 'react-hot-toast';


function error(params) {
  params = params || {}
  const code = params.code || ''
  const title = params.title || ''
  const message = params.message || false

  toast.custom((t) => (
    <div className="bg-red-100 max-w-md w-full flex items-center p-4 bg-white rounded-md shadow-xl relative">
      <svg onClick={() => toast.dismiss(t.id)} className="mb-auto cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill-red-700" d="M14 2V14C14 14.1768 13.9298 14.3464 13.8047 14.4714C13.6797 14.5964 13.5101 14.6667 13.3333 14.6667H2.66667C2.48986 14.6667 2.32029 14.5964 2.19526 14.4714C2.07024 14.3464 2 14.1768 2 14V2C2 1.82319 2.07024 1.65362 2.19526 1.5286C2.32029 1.40357 2.48986 1.33334 2.66667 1.33334H13.3333C13.5101 1.33334 13.6797 1.40357 13.8047 1.5286C13.9298 1.65362 14 1.82319 14 2ZM3.33333 10.6667V13.3333H12.6667V10.6667H3.33333ZM10 11.3333H11.3333V12.6667H10V11.3333Z" />
      </svg>

      <div className="pl-3 w-full">
        <div className="w-full flex items-center justify-between">
          <p tabIndex="0" className="focus:outline-none font-semibold text-sm leading-none text-red-700">{title}</p>
          <p tabIndex="0" className="focus:outline-none text-xs leading-3 text-right text-red-700">{code}</p>
        </div>
        {message && <p className="mt-2 text-xs text-red-700">{message}</p>}
      </div>
    </div>
  ))
}


function success(params) {
  params = params || {}
  const code = params.code || ''
  const title = params.title || ''
  const message = params.message || false

  toast.custom((t) => (
    <div className="bg-green-100 max-w-md w-full flex items-center p-4 bg-white rounded-md shadow-xl relative">
      <svg onClick={() => toast.dismiss(t.id)} className="mb-auto" width="16" height="16" viewBox="0 0 16 16" fill="text-green-700" xmlns="http://www.w3.org/2000/svg">
        <path className="fill-green-700" d="M8.00059 3.01934C9.56659 1.61334 11.9866 1.66 13.4953 3.17134C15.0033 4.68334 15.0553 7.09133 13.6526 8.662L7.99926 14.3233L2.34726 8.662C0.944589 7.09133 0.997256 4.67934 2.50459 3.17134C4.01459 1.662 6.42992 1.61134 8.00059 3.01934Z" />
      </svg>

      <div className="pl-3 w-full">
        <div className="w-full flex items-center justify-between">
          <p tabIndex="0" className="focus:outline-none font-semibold text-sm leading-none text-green-700">{title}</p>
          <p tabIndex="0" className="focus:outline-none text-xs leading-3 text-right text-green-700">{code}</p>
        </div>
        {message && <p className="mt-2 text-xs text-green-700">{message}</p>}
      </div>
    </div>
  ))
}


function styled(params) {
  params = params || {}
  const code = params.code || ''
  const title = params.title || ''
  const message = params.message || false

  toast.custom((t) => (
    <div className="max-w-md flex items-end p-4 rounded-md shadow-xl relative bg-gradient-to-l from-red-500/80 to-purple-500/80">
      <aside className="z-50 w-full flex items-center justify-end gap-4 rounded-lg px-5 py-3 text-white bg-gradient-to-l from-indigo-500/40 via-purple-500/40 to-pink-500/40">
        <p className="text-sm font-medium"> {title} </p>
        <button onClick={() => toast.dismiss(t.id)} className="rounded bg-white/20 p-1 hover:bg-white/10">
          <span className="sr-only">Close</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </aside>
    </div>
  ))
}


export default {
  error,
  success,
  styled,
}
