import { ethers } from 'ethers'

import xen_abi from '../assets/xen.abi.json'
import xenvault_abi from '../assets/XenVault.json'
import xenvaultview_abi from '../assets/XenVaultView.json'
import xenvaulterc20_abi from '../assets/XenVaultERC20.json'
import networks from '../assets/networks'


let contracts = {
  xen: null,
  xenvault: null,
  xenvaultview: null,
  xenvaulterc20: null,
};

export async function initContracts() {
  try {
    const {ethereum} = window
    const provider = new ethers.BrowserProvider(ethereum)
    const signer = await provider.getSigner()

    const chainId = signer.provider._network.chainId;
    const addresses = networks[chainId];

    if (!addresses.enabled) {
      return;
    }

    if (addresses) {
      const contracts = {
        xen: new ethers.Contract(addresses.xen, xen_abi, signer),
        xenvault: new ethers.Contract(addresses.xenvault, xenvault_abi, signer),
        xenvaultview: new ethers.Contract(addresses.xenvaultview, xenvaultview_abi, signer),
        xenvaulterc20: new ethers.Contract(addresses.xenvaulterc20, xenvaulterc20_abi, signer),
      };

      return contracts;
    } else {
      // console.log(`No addresses found for chainId ${chainId}`);
      return null;
    }
  } catch (e) {
    // console.log(e)
  }
}

export async function getSigner() {
  let signer
  try {
    const {ethereum} = window
    const provider = new ethers.BrowserProvider(ethereum)
    signer = await provider.getSigner()
  } catch (e) {
    console.log(e)
  }

  return signer;
}

export async function getNetwork() {
  let network
  try {
    const {ethereum} = window
    const provider = new ethers.BrowserProvider(ethereum)
    const signer = await provider.getSigner()

    const chainId = signer.provider._network.chainId;
    network = networks[chainId]; 
  } catch (e) {
    // console.log(e)
  }

  return network;
}
