module.exports = {
    // 1: {name: 'Ethereum', icon: ""},

    250: {
        chainId: 250,
        name: 'Fantom',
        ticker: 'fmXVT',
        native: 'FTM',
        xenname: 'fmXEN',
        baserate: BigInt(93372549),
        minimumFee: BigInt(15e16),
        divisor: BigInt(1e18),
        rpcurl: 'https://fantom.publicnode.com',
        explorer: 'https://ftmscan.com/',
        xen: '0xeF4B763385838FfFc708000f884026B8c0434275',
        xenvault: '0x04B5b3fD23c96f9032Ec7e546b7c0a1ccfdC2d9D',
        xenvaultview: '0xb8c655E6DCE0B9E696FEBCa10951600413e75DF7',
        xenvaulterc20: '0xe520eCE6d17B84423764e98d3fB5eE8EFaAD6A9D',
        enabled: true,
    },
    56: {
        chainId: 56,
        name: 'BSC',
        ticker: 'bXVT',
        native: 'BNB',
        xenname: 'bXEN',
        baserate: BigInt(23372549),
        minimumFee: BigInt(12e14),
        divisor: BigInt(1e19),
        rpcurl: 'https://bsc.publicnode.com',
        explorer: 'https://bscscan.com',
        xen: '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
        xenvault: '0xC712ba826c877AC883Adf1FC6Ec173962fB7794D',
        xenvaultview: '0xeBccEca2e3F61F90a77498F7c157ac14842a2370',
        xenvaulterc20: '0x1EbC2f4B2a44B78d896d81deB561DFb467133E5A',
        enabled: true,
    },
    137: {
        chainId: 137,
        name: 'Polygon',
        ticker: 'mXVT',
        native: 'MATIC',
        xenname: 'mXEN',
        baserate: BigInt(93372549),
        minimumFee: BigInt(15e16),
        divisor: BigInt(1e18),
        rpcurl: 'https://polygon-bor.publicnode.com',
        explorer: 'https://polygonscan.com',
        xen: '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
        xenvault: '0xD012b32b18B2921065f727B65172f921DFDc1532',
        xenvaultview: '0x7F4f3ac8F0A9F75f68B32255AED2b6F5f5216631',
        xenvaulterc20: '0xC6468796091CaDab081cE950C758d280Eca69a40',
        enabled: true,
    },
    1284: {
      chainId: 1284,
      name: 'Moonbeam',
      ticker: 'mbXVT',
      native: 'GLMR',
      xenname: 'mbXEN',
      baserate: BigInt(183372549),
      minimumFee: BigInt(15e16),
      divisor: BigInt(1e18),
      rpcurl: 'https://moonbeam.publicnode.com',
      explorer: 'https://moonbeam.moonscan.io/',
      xen: '0xb564A5767A00Ee9075cAC561c427643286F8F4E1',
      xenvault: '0xeBccEca2e3F61F90a77498F7c157ac14842a2370',
      xenvaultview: '0xbA1791649D3e70Be1181bD7204e6Ef93308644A9',
      xenvaulterc20: '0xAb197A16a5c62c94961CA7dDE3C5d0fC31BC5e02',
      enabled: true,
    },

    202212: {
      chainId: 202212,
      name: 'X1 Devnet',
      ticker: 'xdXVT',
      native: 'XN',
      xenname: 'XEN',
      baserate: BigInt(93372549),
      minimumFee: BigInt(15e16),
      divisor: BigInt(1e18),
      rpcurl: 'https://x1-devnet.xen.network',
      explorer: 'https://explorer.x1-devnet.xen.network/',
      xen: '0xD342D63466B520d8D331CaFF863900d402Aa5b00',
      xenvault: '0x505f5f80F9494978f1984b3bE593450430022d8B',
      xenvaultview: '0x65ba62B846b6a62E8E102AfB37fEDAbC379cBEb1',
      xenvaulterc20: '0x2c7BBEe178bc38A94957D39964c482931BD72A92',
      enabled: true,
    },
    4003: {
        chainId: 4003,
        name: 'X1 Fastnet',
        ticker: 'xfXVT',
        native: 'XN',
        xenname: 'XEN',
        baserate: BigInt(93372549),
        minimumFee: BigInt(15e16),
        divisor: BigInt(1e18),
        rpcurl: 'https://x1-fastnet.infrafc.org',
        explorer: 'https://explorer.x1-fastnet.infrafc.org/',
        xen: '0x784005E14a12545f22f53E6325bd2a2172237414',
        xenvault: '0xC712ba826c877AC883Adf1FC6Ec173962fB7794D',
        xenvaultview: '0xeBccEca2e3F61F90a77498F7c157ac14842a2370',
        xenvaulterc20: '0x1EbC2f4B2a44B78d896d81deB561DFb467133E5A',
        enabled: true,
    },

    369: {
      chainId: 369,
      name: 'Pulsechain',
      ticker: 'pXVT',
      native: 'PLS',
      xenname: 'pXEN',
      baserate: BigInt(163372549),
      minimumFee: BigInt(5e20),
      divisor: BigInt(1e14),
      rpcurl: 'https://pulsechain.publicnode.com',
      explorer: 'https://scan.pulsechain.com',
      xen: '0x8a7FDcA264e87b6da72D000f22186B4403081A2a',
      xenvault: '0x2A0D00B5797C9417462B7FB137eF60F43A120844',
      xenvaultview: '0xC712ba826c877AC883Adf1FC6Ec173962fB7794D',
      xenvaulterc20: '0x154C978EeC9E8D27C15E7Ba1e69e0e7b6DCEF942',
      enabled: true,
  },


    43114: {
        chainId: 43114,
        name: 'Avalanche',
        ticker: 'aXVT',
        native: 'AVAX',
        xenname: 'aXEN',
        baserate: BigInt(17372549),
        minimumFee: BigInt(8e15),
        divisor: BigInt(1e18),
        rpcurl: 'https://avalanche-c-chain.publicnode.com',
        explorer: 'https://snowtrace.io/',
        xen: '0xC0C5AA69Dbe4d6DDdfBc89c0957686ec60F24389',
        // xenvault: '0xD012b32b18B2921065f727B65172f921DFDc1532',
        // xenvaultview: '0x7F4f3ac8F0A9F75f68B32255AED2b6F5f5216631',
        // xenvaulterc20: '0xC6468796091CaDab081cE950C758d280Eca69a40',
        enabled: false,
    },
    
    943: {
        chainId: 943,
        name: 'Pulsechain Testnet V4',
        ticker: 'pXVT',
        native: 'tPLS',
        xenname: 'pXEN',
        baserate: BigInt(93372549),
        minimumFee: BigInt(15e21),
        divisor: BigInt(1e14),
        rpcurl: 'https://rpc.v4.testnet.pulsechain.com',
        explorer: 'https://scan.v4.testnet.pulsechain.com',
        xen: '0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8'.toLowerCase(),
        xenvault: '0x2A0D00B5797C9417462B7FB137eF60F43A120844'.toLowerCase(),
        xenvaultview: '0xC712ba826c877AC883Adf1FC6Ec173962fB7794D'.toLowerCase(),
        xenvaulterc20: '0x154C978EeC9E8D27C15E7Ba1e69e0e7b6DCEF942'.toLowerCase(),
        enabled: false,
    },

    43112: {
      chainId: 43112,
      name: 'Localhost',
      ticker: 'lXVT',
      native: 'lETH',
      xenname: 'lXEN',

      // pulsechain
      baserate: BigInt(163372549),
      minimumFee: BigInt(5e20),
      divisor: BigInt(1e14),

      //   baserate: BigInt(17372549),
      //   minimumFee: BigInt(8e15),
      //   divisor: BigInt(1e18),


      //   baserate: BigInt(93372549),
      //   minimumFee: BigInt(15e16),
      //   divisor: BigInt(1e18),

      //   // avax
      //   baserate: BigInt(23372549),
      //   minimumFee: BigInt(12e14),
      //   divisor: BigInt(1e19),

      // // 
      // baserate: BigInt(183372549),
      // minimumFee: BigInt(15e16),
      // divisor: BigInt(1e18),
      xen: '0xbc481dcc857C1f7472C81E2552E229B157b2B495',
      xenvault: '0x7c8f36Ac5DE6d254cEd4721252758eafdd362758',
      xenvaultview: '0xcd5cF3fD3F4a34AE3B233C702Ff38B96994e9e4D',
      xenvaulterc20: '0x92170d90C3Ab78D6841bf1f6DF39F9032347cc7c',
      enabled: false,
    }
}


