import { createContext, useState, useContext, useEffect, useRef, useMemo } from 'react'
import { ethers } from 'ethers'
import toast from '../components/toast'

import { initContracts } from '../lib/contracts';
import vault_abi from '../assets/Vault.json'

const VaultContext = createContext()

export const VaultProvider = ({ children }) => {
  const [state, setState] = useState({}) 
  const [vaults, setVaults] = useState([])
  const [userStats, setUserStats] = useState({
    xenBalance: 0,
    vaultBalance: 0,
    totalVaults: 0,
    totalLocked: 0,
    feeReward: 0,
    xenFeeReward: 0,
    vaultReward: 0,
    totalStaked: 0,
    withdrawbleStake: 0,
  })


  function calculateStakeReward(amount, term, maturityTs, apy) {
      let rate = (apy * term * 1_000_000n) / 365n;
      return (amount * rate) / 100_000_000n
  }


  async function retrieveData(loc) {
    try {
      const {ethereum} = window
      const provider = new ethers.BrowserProvider(ethereum)
      const signer = await provider.getSigner()
      const contracts = await initContracts()

      const vault_addresses = await contracts.xenvault.getVaults(signer.address)
      let vaults = []
      let totalLocked = 0n
      let totalVaults = 0n

      for (const address of vault_addresses) {       
        const vault_instance = new ethers.Contract(address, vault_abi, provider)

        const unlockedTs = await vault_instance.unlockedTs()
        const amount = await vault_instance.amount()

        const vaultObject = {
          term: Number(await vault_instance.term()),
          duedate: Number(await vault_instance.maturityTs()),
          amount,
          apy: Number(await vault_instance.apy()),
          unlocked: Boolean(unlockedTs),
          unlockedTs,
          penalty: await vault_instance.penaltyAmount(),
          address
        }

        const reward = calculateStakeReward(vaultObject.amount, BigInt(vaultObject.term), BigInt(vaultObject.duedate), BigInt(vaultObject.apy))
        vaultObject.full = vaultObject.amount + BigInt(reward)

        if (!Boolean(unlockedTs)) {
          totalVaults++
          totalLocked += amount
        }

        vaults.push(vaultObject)
      }
      totalLocked = ethers.formatEther(totalLocked)

      const xenBalance = await contracts.xen.balanceOf(signer.address)
      const vaultBalance = await contracts.xenvaulterc20.balanceOf(signer.address)
      const feeReward = ethers.formatEther(await contracts.xenvaultview.getUncollectedFees(signer.address))
      const xenFeeReward = ethers.formatEther(await contracts.xenvaultview.getUncollectedXenFees(signer.address))
      const vaultReward = ethers.formatEther(await contracts.xenvaultview.getUncollectedRewards(signer.address))
  
      let initStake = await contracts.xenvault.initStake(signer.address)
      let secStake = await contracts.xenvault.secStake(signer.address)
      let initStakeAmount = await contracts.xenvault.accStakeCycle(signer.address, initStake);
      let secStakeAmount = await contracts.xenvault.accStakeCycle(signer.address, secStake);
      let withdrawbleStake = await contracts.xenvault.accWithdrawableStake(signer.address)

      const totalStaked = ethers.formatEther(initStakeAmount + secStakeAmount + withdrawbleStake);

      setVaults(vaults)
      setUserStats({
        xenBalance,
        vaultBalance,
        totalVaults,
        totalLocked,
        feeReward,
        xenFeeReward,
        vaultReward,
        totalStaked,
        withdrawbleStake
      })
    } catch(e) {
      // console.log(e)
      // console.log(Object.keys(e))

      if (e.code == 'BAD_DATA') {
        toast.error({
          "code": e.code,
          "title": e.code,
          "message": "XenVault error"
        });
      }
    }
  }


  async function accountsChanged() {
    retrieveData('accountsChanged')
  }

  async function chainChanged() {
    retrieveData('chainChanged')
  }


  useEffect(() => {
    try {
      window.ethereum.on('accountsChanged', accountsChanged);
      window.ethereum.on('chainChanged', chainChanged);
  
      retrieveData('callee useEffect')
    } catch (e) {
      // console.log(e)
    }

    return () => {
      try {
        window.ethereum.removeListener('accountsChanged', accountsChanged);
        window.ethereum.removeListener('chainChanged', chainChanged);
      } catch (e) {
        // console.log(e)
      }
    };
  }, [useRef(vaults)]);

  return (
    <VaultContext.Provider value={{ 
      state,
      vaults,
      retrieveData,
      userStats
    }}>
      {children}
    </VaultContext.Provider>
  );

}

export function useVault() {
  return useContext(VaultContext);
}
