import React, { useState, useEffect } from 'react'
import { getNetwork } from '../lib/contracts'

export default function Footer() {
  const [network, setNetwork] = useState()

  useEffect(() => {
    async function retrieveNetwork() {
      setNetwork(await getNetwork())
    }

    retrieveNetwork()
  }, [])

  async function handleWalletChanges() {
    setNetwork(await getNetwork())
  }

  useEffect(() => {
    try {
      window.ethereum.on('accountsChanged', handleWalletChanges);
      window.ethereum.on('chainChanged', handleWalletChanges);
    } catch (e) {
      // console.log(e)
    }

    return () => {
      try {
        window.ethereum.removeListener('accountsChanged', handleWalletChanges);
        window.ethereum.removeListener('chainChanged', handleWalletChanges);
      } catch (e) {
      }
    };
  }, []);

  let contract_address,
      token_address
  if (network && network.enabled) {
    contract_address = `${network.explorer}/address/${network.xenvault}`
    token_address = `${network.explorer}/address/${network.xenvaulterc20}`
  }

  return (
    <div className="text-center text-xs">
      <a href="https://xenvault.com/litepaper" target="_blank" className="mr-4">whitepaper</a>
      <a href={contract_address} target="_blank" className="mr-4">contract</a>
      <a href={token_address} target="_blank" className="mr-4">token address</a>
    </div>
  )
}
