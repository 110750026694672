import React from 'react'
import toast from '../components/toast'

import { ethers } from 'ethers'

import { useVault } from '../context/VaultContext'
import { initContracts, getSigner } from '../lib/contracts'


export default function Vaults({ openModal, setUnLockLogic }) {
  const { 
    vaults,
    retrieveData,
  } = useVault()

  async function handleUnlockXen (e, index, percentage) {
    e.preventDefault()

    if (percentage < 100) {
      openModal(true)
      setUnLockLogic(() => () => unLockLogic(index));
      return;
    } else {
      unLockLogic(index)
    }
  }

  async function unLockLogic(index) {
    
    try {
      const contracts = await initContracts()
      const signer = await getSigner()
      const transaction =  await contracts.xenvault.connect(signer).unlock(vaults[index]['address'])
      const receipt = await transaction.wait()
      retrieveData()
    } catch (e) {
      // console.log(e)

      if (e.code == 'CALL_EXCEPTION') {
        toast.error({
          "code": e.info.error.code,
          "title": e.info.error.message,
          "message": e.info.error.data.data.message
        });
      } else if (e.code == 'ACTION_REJECTED') {
        toast.error({
          "code": e.info.error.code,
          "title": e.code,
          "message": e.info.error.message,
        });
      } else if (e.code == 'UNKNOWN_ERROR') {
        toast.error({
          "code": e.error.code,
          "title": e.code,
          "message": e.error.message,
        });
      }
    }
  }

  function calculatePercentagePassed(duedate, term) {
    const _term = term * 24 * 60 * 60
    const start = duedate - _term;
    const current = Math.floor(Date.now() / 1000);
    const total = duedate - start;
    const elapsed = current - start;
    const percentage = Math.max(0, (elapsed / total) * 100);

    return Math.floor(percentage)
  }

  return (
    <section className="py-1 text-gray-800 dark:text-white mb-8">

      <div className="w-full px-12 mx-auto mt-24">

        <div className="rounded-lg bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-[3px] text-gray-800">
          <span className="block rounded-lg bg-white dark:bg-[#0F172A] text-sm font-medium dark:text-white">    
            <div className="relative flex flex-col min-w-0 break-words w-full rounded bg-white dark:bg-transparent">
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base"> XEN Vaults </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <button className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hidden" type="button"> Hide Unlocked </button>
                  </div>
                </div>
              </div>
              <div className="block w-full overflow-x-auto">
                <table className="items-center w-full border-collapse">
                  <thead className="thead-light ">
                    <tr>
                      <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"> Term </th>
                      <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"> Due Date </th>
                      <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"> Amount </th>
                      <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"> Full Reward </th>
                      <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"> Progress </th>
                      <th className="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"></th>
                    </tr>
                  </thead>
                  <tbody>

                  {
                    vaults &&
                    vaults.map( (vault, index) => {
                      const percentage = calculatePercentagePassed(vault.duedate, vault.term)

                      let bgColor = 'bg-red-200'
                      let barColor = 'bg-red-500'

                      if (percentage < 30) {
                        bgColor = 'bg-red-200'
                        barColor = 'bg-red-500'
                      } else if (percentage > 30 && percentage < 70) {
                        bgColor = 'bg-orange-200'
                        barColor = 'bg-orange-500'
                      } else if (percentage > 70) {
                        bgColor = 'bg-green-200'
                        barColor = 'bg-green-500'
                      }

                      return (
                        <tr className={vault.unlocked && `bg-gradient-to-r from-red-500/40 to-purple-500/40` }>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {vault.term} </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {new Date(vault.duedate*1000).toISOString()} </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {Number(ethers.formatEther(vault.amount)).toLocaleString('en-us')} </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {Number(ethers.formatEther(vault.full)).toLocaleString('en-us')}
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {
                              vault.unlocked ? "Unlocked" :
                                <div className="flex items-center">
                                  <span className="mr-2">{percentage}</span>
                                  <div className="relative w-full">
                                    <div className={`overflow-hidden h-2 text-xs flex rounded ${bgColor}`}>
                                      <div style={{"width": percentage+'%'}} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${barColor}`}></div>
                                    </div>
                                  </div>
                                </div>
                            }
                          </td>
                          <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {
                              vault.unlocked ? 
                                <button type="button" className="hidden inline-flex items-center justify-center py-2 px-3 rounded-lg bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition">
                                  {'Claim X1'}
                                </button>
                              :
                                <button onClick={(e) => {handleUnlockXen(e, index, percentage)}} type="button" className="inline-flex items-center justify-center py-2 px-3 rounded-lg bg-white text-gray-800 hover:text-green-500 text-sm font-semibold transition">
                                  {percentage >= 100 ? 'Unlock' : 'Unlock Early'}
                                </button>
                            }
                          </td>
                        </tr>
                      )

                    })
                  }

                  </tbody>
                </table>
              </div>
            </div>
          </span>
        </div>

      </div>
    </section>
  )
}
