
import './App.css'
import React, { Suspense, useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Breadcrumb from './components/breadcrumb'
import Sidebar from './components/sidebar'
import Header from './components/header'
import Modal from './components/modal'
import Footer from './components/footer'

import VaultLock from './pages/vaultlock'
import Vaults from './pages/vaults'
import Stats from './pages/stats'
import Page404 from './pages/404'

function App() {
  const [modal, setModal] = useState(false)
  const [unLockLogic, setUnLockLogic] = useState(false)

  function handleAccept () {   
    if (unLockLogic) {
      unLockLogic();
    }
    setModal(false);
  }

  function handleDecline () {
    setModal(false)
  }

  return (
    <Suspense>
      <div className="body bg-white dark:bg-[#0F172A]">

        <Modal isOpen={modal} onAccept={handleAccept} onDecline={handleDecline} unLockLogic={unLockLogic} />

        <Toaster position="top-right" containerStyle={{ top: 70 }} />
        <Header />
        <Sidebar />

        <div className="content ml-12 transform ease-in-out duration-500 pt-20 px-2 md:px-5 pb-4">
          <Breadcrumb />

          <Routes>
            <Route path="/" element={<VaultLock />} />
            <Route path="/vaults" element={<Vaults openModal={setModal} setUnLockLogic={setUnLockLogic} />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="*" element={<Page404 />} />
          </Routes>

          <Footer />
        </div>

      </div>
    </Suspense>
  );
}

export default App;
