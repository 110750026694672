import React, { createContext, useState, useContext } from 'react'

// this is where the chain id will be stored
// when ever the user updates the dropdown or metamask this will be updated

const ChainContext = createContext()

export const useChain = () => useContext(ChainContext)

export const ChainProvider = ({ children }) => {
  const [chain, setChain] = useState(null)

  const value = {
    chain,
    setChain
  }

  // console.log('-------- ChainProvider')
  // console.log(chain)

  return (
    <ChainContext.Provider value={value}>
      {children}
    </ChainContext.Provider>
  )
}
